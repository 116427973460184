import { useCommonStore } from "../../Store/CommonStore";

const PayMeService = {

    CreateNewSale(request: any) {

        const data = JSON.stringify(request)

        return fetch(`${process.env.VUE_APP_ROOT_API}/PayMe/GetNewSaleUrl`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        }).then(response => response.json()).
            then((response) => {
                return response;
            });
    },

    GetSaleStatus(payMeSaleCode: string) {

        const CommonStore = useCommonStore();

        const request = {
            TerminalNumber: CommonStore.TerminalNumber,
            SaleCode: payMeSaleCode,
            SuccessUrl: CommonStore.SuccessRedirectUrl,
            ErrorUrl: CommonStore.ErrorRedirectUrl
        };
        const data = JSON.stringify(request)

        return fetch(`${process.env.VUE_APP_ROOT_API}/PayMe/GetSaleStatus`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        }).then(response => response.json()).
            then((response) => {
                return response;
            });
    }

}

export default PayMeService;
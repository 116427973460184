import { defineStore } from 'pinia'
import { FormField } from '../components/input/FormField'
import { isNullOrWhitespace } from '../utils'

export const useCommonStore = defineStore('CommonStore', {
    state: () => {

        return {
            Uid: '',
            OldUid: '',
            TerminalNumber: 0,
            ContinueClickButtonAction: () => { },
            StoreInitialized: false,
            SuccessRedirectUrl: '',
            ErrorRedirectUrl: '',
            BackgroundImageUrl: '',
            AdditionalDataJson: '',
            Language: '',
            UniqTempDealID: '',
            IsError: false,
            ErrorCode: 0,
            ErrorMessage: '',
            OpeningText: '',
            IsSinglePage: false,
            CurrentCardcomBitIntervalId: 0
        }
    },
    getters: {
    }
})
import { defineStore } from 'pinia'
import { FormField } from '../components/input/FormField'
import { isNullOrWhitespace } from '../utils'


export const useDetailsStore = defineStore('DetailsStore', {
    state: () => {

        return {
            ShowDetailsForm: false,
            Comments: '',
            FormFields: <Array<FormField>>[]
        }
    },
    getters: {
    }
})



//import { createApp } from 'vue/dist/vue.esm-bundler';
import { computed, createApp, markRaw } from 'vue'
import { i18nConfig } from './translate'
import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'
import { myRouter } from './RouterMy'
import App from './App.vue'
import * as Sentry from "@sentry/vue";
import { usePageSelectionEA5 } from './Store/PageSelectionEA5'
import { BrowserTracing } from '@sentry/tracing'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import LoadScript from 'vue-plugin-load-script';
import { useCommonStore } from './Store/CommonStore'

const head = createHead()
const app = createApp(App);
// documentation : https://vue-i18n.intlify.dev/introduction.html
// examples: https://github.com/intlify/vue-i18n-next/tree/master/examples/composition


Sentry.init({
    app,
    dsn: "https://b9f2b88c3eba49588f8ae757ba0d8437@o51608.ingest.sentry.io/4504093882253312",  
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(myRouter),
            tracingOrigins: ["localhost", "https://vgilad.cardcom.solutions/", "https://v.cardcom.solutions/", /^\//],
        }),
        new Sentry.Replay()
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.VUE_APP_ENV_NAME,
    logErrors: true,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

app.use(i18nConfig);
app.use(head); //https://github.com/vueuse/head#api
const pinia = createPinia()
pinia.use(({ store }) => {
    store.router = markRaw(myRouter)
});
app.use(pinia)  // https://pinia.vuejs.org/getting-st+arted.html#installation
app.use(myRouter);
app.use(LoadScript);

const isCaptchaV3 = (window as { [key: string]: any })["IsCaptchaV3"];

if (isCaptchaV3 == true) {
    app.use(VueReCaptcha, {
        siteKey: '6Ld7ShcoAAAAAJT8z3Y18pqMAqx1x2KLrKo9iutE',
        loaderOptions: {
            useRecaptchaNet: true
        }
    })
}

/*app.use(createMetaManager());*/
app.mount('#app')
const PageSelectionEA5 = usePageSelectionEA5();
const CommonSture = useCommonStore();
myRouter.beforeEach((to, from) => {
    if (CommonSture.IsSinglePage && to.name != 'PaymentSinglePage') {
        const wizardPages = ['IntroPage', 'Order', 'DonationPage', 'DeliveryPage', 'DocumentPage', 'DetailsPage', 'PaymentPage'];
        const pageName = to.name?.toString();
        const isWizardPage = wizardPages.find(p => p == pageName) != undefined;
        if (isWizardPage) {
            // single page mode. unable to navigate to wizard page
            return false;
        }
    }
});
myRouter.afterEach((to, from) => {
    PageSelectionEA5.afterEach(to, from);
});


import { defineStore } from 'pinia'
import { RouteLocationNormalized, RouteRecordName } from "vue-router";
import { usePaymentStore } from './PaymentStore';

export class menuItem {
    public id = 0;
    public imageUrl = "";
    public i18Name = "";
    public i18NameAlt = "";
    public ComponentRoutName = "";
    public IsActive = false;
    public menuType = menuItemType.Generic;
}
export enum menuItemType {
    Generic = 0,
    Order = 1,
    Donation,
    Delivery,
    Document,
    Details,
    Payment,
}
export const usePageSelectionEA5 = defineStore('PageSelectionEA5', {
    state: () => {
        return {
            ShowIntro: false,
            ShowOrder: false,
            ShowDonation: false,
            ShowDelivery: false,
            ShowDocument: false,
            ShowDetails: false,
            ShowPayment: false,
            menuItems: <Array<menuItem>>[]
        }
    },
    actions: {
        IsLastPage() {
            const activeMenuItem = this.menuItems.find(item => item.IsActive);
            if (activeMenuItem != undefined) {
                const activeMenuItemIndex = this.menuItems.indexOf(activeMenuItem);
                if (activeMenuItemIndex > -1) {
                    return activeMenuItemIndex == this.menuItems.length - 1;
                }
            }
            return false;
        },
        SetAsActive(item: menuItem) {
            item.IsActive = true;
            this.router.push({ name: item.ComponentRoutName })

        },
        SetAsActiveByName(to: RouteLocationNormalized) {
            if (this.menuItems.length == 0) {
                return;
            }
            if (this.menuItems.length == 1) {
                this.menuItems[0].IsActive = true;
                return;
            }
            for (let i = 0; i < this.menuItems.length; i++) {
                // order importent !
                if (this.menuItems[i].ComponentRoutName == to.name && this.menuItems[i].IsActive) {
                    break;
                }

                this.menuItems[i].IsActive = false;

                if (this.menuItems[i].ComponentRoutName == to.name) {
                    this.SetAsActive(this.menuItems[i]);
                }
            }
        },
        Next() {
            if (this.menuItems.length == 0) {
                return;
            }
            if (this.menuItems.length == 1) {
                this.menuItems[0].IsActive = true;
                return;
            }

            for (let i = 1; i < this.menuItems.length; i++) {
                if (this.menuItems[i - 1].IsActive) {
                    this.menuItems[i - 1].IsActive = false;
                    this.SetAsActive(this.menuItems[i]);
                    break;
                }
            }
        },
        Back() {
            if (this.menuItems.length == 0) {
                return;
            }
            if (this.menuItems.length == 1) {
                this.menuItems[0].IsActive = true;
                return;
            }

            for (let i = 1; i < this.menuItems.length; i++) {
                if (this.menuItems[i].IsActive) {
                    this.menuItems[i].IsActive = false;
                    this.SetAsActive(this.menuItems[i - 1]);
                    break;
                }
            }
        },
        GetActive() {
            return this.menuItems.find((itm) => {
                return itm.IsActive;
            });
        },
        SetMenuItems() {
            this.menuItems = [];
            if (this.menuItems.length > 0) {
                return;
            }

            let _pageIndex = 0;
            let _menuItem = new menuItem();

            if (this.ShowIntro) {
                _menuItem = new menuItem();
                _menuItem.id = _pageIndex;
                _menuItem.i18Name = "ps.Intro";
                _menuItem.i18NameAlt = "ps.IntroAlt";
                _menuItem.imageUrl = "/EA6/gfx/order-icon.svg";
                _menuItem.ComponentRoutName = "IntroPage";
                _menuItem.IsActive = false;
                _menuItem.menuType = menuItemType.Generic;
                this.menuItems.push(_menuItem);
                _pageIndex++;
            }

            if (this.ShowOrder) {
                _menuItem = new menuItem();
                _menuItem.id = _pageIndex;
                _menuItem.i18Name = "ps.Order";
                _menuItem.i18NameAlt = "ps.OrderAlt";
                _menuItem.imageUrl = "/EA6/gfx/order-icon.svg";
                _menuItem.ComponentRoutName = "Order";
                _menuItem.IsActive = false;
                _menuItem.menuType = menuItemType.Order;
                this.menuItems.push(_menuItem);
                _pageIndex++;
            }

            if (this.ShowDonation) {
                _menuItem = new menuItem();
                _menuItem.id = _pageIndex;
                _menuItem.i18Name = "ps.Donation";
                _menuItem.i18NameAlt = "ps.DonationAlt";
                _menuItem.imageUrl = "/EA6/gfx/order-icon.svg";
                _menuItem.ComponentRoutName = "DonationPage";
                _menuItem.IsActive = false;
                _menuItem.menuType = menuItemType.Donation;
                this.menuItems.push(_menuItem);
                _pageIndex++;
            }

            if (this.ShowDelivery) {
                _menuItem = new menuItem();
                _menuItem.id = _pageIndex;
                _menuItem.i18Name = "ps.Delivery";
                _menuItem.i18NameAlt = "ps.DeliveryAlt";
                _menuItem.imageUrl = "/EA6/gfx/shipping-icon.svg";
                _menuItem.ComponentRoutName = "DeliveryPage";
                _menuItem.IsActive = false;
                _menuItem.menuType = menuItemType.Delivery;
                this.menuItems.push(_menuItem);
                _pageIndex++;
            }

            if (this.ShowDocument) {
                _menuItem = new menuItem();
                _menuItem.id = _pageIndex;
                _menuItem.i18Name = "ps.Document";
                _menuItem.i18NameAlt = "ps.DocumentAlt";
                _menuItem.imageUrl = "/EA6/gfx/details-icon.svg";
                _menuItem.ComponentRoutName = "DocumentPage";
                _menuItem.IsActive = false;
                _menuItem.menuType = menuItemType.Document;
                this.menuItems.push(_menuItem);
                _pageIndex++;
            }

            if (this.ShowDetails) {
                _menuItem = new menuItem();
                _menuItem.id = _pageIndex;
                _menuItem.i18Name = "ps.Details";
                _menuItem.i18NameAlt = "ps.DetailsAlt";
                _menuItem.imageUrl = "/EA6/gfx/details-icon.svg";
                _menuItem.ComponentRoutName = "DetailsPage";
                _menuItem.IsActive = false;
                _menuItem.menuType = menuItemType.Details;
                this.menuItems.push(_menuItem);
                _pageIndex++;
            }

            if (this.ShowPayment) {
                _menuItem = new menuItem();
                _menuItem.id = _pageIndex;
                _menuItem.i18Name = "ps.Payment";
                _menuItem.i18NameAlt = "ps.PaymentAlt";
                _menuItem.imageUrl = "/EA6/gfx/payment-icon.svg";
                _menuItem.ComponentRoutName = "PaymentPage";
                _menuItem.IsActive = false;
                _menuItem.menuType = menuItemType.Payment;
                this.menuItems.push(_menuItem);
                _pageIndex++;
            }

            if (this.menuItems.length > 0) {
                this.menuItems[0].IsActive = true;
            }

        },

        afterEach(to: RouteLocationNormalized, from: RouteLocationNormalized) {
            this.SetAsActiveByName(to)
        }
    }
})

import { defineStore } from 'pinia'
import { FormField } from '../components/input/FormField'
import { isNullOrWhitespace } from '../utils'


export const useDocumentStore = defineStore('DocumentStore', {
    state: () => {
        return {
            ShowInvoiceForm: false,
            Comments: '',
            Title: '',
            FormFields: <Array<FormField>>[],
            SendPreBuyDataToServer: false
        }
    },
    getters: {
    }
})



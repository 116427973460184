import { usePaymentStore } from "../../../Store/PaymentStore";
import { useProductsStore } from "../../../Store/ProductSelection";
import { useCompanyInfoStore } from "../../../Store/CompanyInfoStore";
import { useCommonStore } from "../../../Store/CommonStore";
import { loadScript } from "vue-plugin-load-script";

class GooglePayManager {
    public PaymentStore = usePaymentStore();
    public ProductsStore = useProductsStore();
    public CompanyInfoStore = useCompanyInfoStore();
    public CommonStore = useCommonStore();

    _clientSideClick: () => void;
    _serverSideSubmit: Function;

    config = {
        /**
         * Define the version of the Google Pay API referenced when creating your
         * configuration
         *
         * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentDataRequest|apiVersion in PaymentDataRequest}
         */
        baseRequest: {
            apiVersion: 2,
            apiVersionMinor: 0,
        },
        /**
         * Identify your gateway and your site's gateway merchant identifier
         *
         * The Google Pay API response will return an encrypted payment method capable
         * of being charged by a supported gateway after payer authorization
         *
         * @todo check with your gateway on the parameters to pass
         * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#gateway|PaymentMethodTokenizationSpecification}
         */
        tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            parameters: {
                gateway: 'cardcom',
                gatewayMerchantId: 'cardcom'
            },
        },
        /**
         * Card networks supported by your site and your gateway
         *
         * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
         * @todo confirm card networks supported by your site and gateway
         */
        allowedCardNetworks: [
            'AMEX',
            'MASTERCARD',
            'VISA',
        ],
        /**
         * Card authentication methods supported by your site and your gateway
         *
         * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
         * @todo confirm your processor supports Android device tokens for your
         * supported card networks
         */
        allowedCardAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
    };

    baseCardPaymentMethod = {
        type: 'CARD',
        parameters: {
            allowedAuthMethods: this.config.allowedCardAuthMethods,
            allowedCardNetworks: this.config.allowedCardNetworks,
        },
    };

    cardPaymentMethod = Object.assign({}, this.baseCardPaymentMethod, {
        tokenizationSpecification: this.config.tokenizationSpecification,
    });

    paymentsClient: any = null;

    getGoogleIsReadyToPayRequest = () => {
        return Object.assign({}, this.config.baseRequest, {
            allowedPaymentMethods: [this.baseCardPaymentMethod],
        });
    };

    getGoogleTransactionInfo = () => ({
        countryCode: 'IL',
        currencyCode: this.ProductsStore.selectedCurrency.AlphabeticCode,
        totalPriceStatus: 'FINAL',
        // set to cart total
        totalPrice: this.ProductsStore.totalCart.toFixed(2),
    });

    constructor(submitAction: Function) {
        this._serverSideSubmit = submitAction;
    }

    LoadGooglePay() {
        loadScript('https://pay.google.com/gp/p/js/pay.js')
            .then(() => {
                this.onGooglePayLoaded();
            })
            .catch(() => {

            });
    }

    PerformPayment() {
        if (this._clientSideClick != undefined) {
            this._clientSideClick();
        }
    }

    getGooglePaymentsClient() {
        if (this.paymentsClient === null) {
            this.paymentsClient = new google.payments.api.PaymentsClient({
                environment: process.env.VUE_APP_ENV_NAME == 'Production' ? 'PRODUCTION' : 'TEST',
                paymentDataCallbacks: {
                    onPaymentAuthorized: (paymentData: any) => this.onPaymentAuthorized(paymentData, this._serverSideSubmit),
                },
            });
        }
        return this.paymentsClient;
    }

    onPaymentAuthorized(paymentData: any, submit: Function): Promise<any> {
        // eslint-disable-next-line no-unused-vars

        async function processPayment(paymentData: any) {
            const paymentToken = paymentData.paymentMethodData.tokenizationData.token;
            await submit(paymentToken);//this method is not async

            //must return a promise to close the payment window.
            //Error handling is done via existing redirect pages
            // eslint-disable-next-line no-unused-vars
            return new Promise(function (resolve, reject) {
                resolve({ transactionState: 'SUCCESS' });
            });
        }

        return new Promise(function (resolve, reject) {
            // handle the response
            processPayment(paymentData)
                .then(function (response: any) {
                    if (response?.Code !== 9999) {//closing the GPay window in case there is no error, in favor of 3DS if needed
                        resolve({ transactionState: 'SUCCESS' });
                    }
                    else {
                        resolve({
                            transactionState: 'ERROR',
                            error: {
                                intent: 'PAYMENT_AUTHORIZATION',
                                message: response.Description[0],
                                reason: 'PAYMENT_DATA_INVALID'
                            }
                        });
                    }
                });
        });
    }

    onGooglePayLoaded() {
        const data = this.getGoogleIsReadyToPayRequest();
        const paymentsClient = this.getGooglePaymentsClient();
        //this is a hack that solves: "Failed to execute 'postMessage' on 'Window': [object Array] could not be cloned"
        const obj = JSON.parse(JSON.stringify(data));
        paymentsClient
            .isReadyToPay(obj)
            .then((response: any) => {
                if (response.result) {

                    const onGooglePaymentButtonClicked = () => {
                        const paymentDataRequest = this.getGooglePaymentDataRequest();
                        paymentDataRequest.transactionInfo = this.getGoogleTransactionInfo();
                        const data = JSON.parse(JSON.stringify(paymentDataRequest));
                        const paymentsClient = this.getGooglePaymentsClient();
                        paymentsClient
                            .loadPaymentData(data)
                            .catch(function (err: any) {
                                // show error in developer console for debugging
                                //console.error(err);
                            });
                    }

                    this._clientSideClick = onGooglePaymentButtonClicked;

                    //const googlePayElement = document.getElementById('google-pay-button');
                    //if (googlePayElement) {
                    //    const onGooglePaymentButtonClicked = () => {
                    //        const paymentDataRequest = this.getGooglePaymentDataRequest();
                    //        paymentDataRequest.transactionInfo = this.getGoogleTransactionInfo();
                    //        const data = JSON.parse(JSON.stringify(paymentDataRequest));
                    //        const paymentsClient = this.getGooglePaymentsClient();
                    //        paymentsClient
                    //            .loadPaymentData(data)
                    //            .catch(function (err: any) {
                    //                // show error in developer console for debugging
                    //                //console.error(err);
                    //            });
                    //    }

                    //    googlePayElement.onclick = function () {
                    //        onGooglePaymentButtonClicked();
                    //    };
                    //}



                    // @todo prefetch payment data to improve performance after confirming site functionality
                    // prefetchGooglePaymentData();
                }
                else {
                    document.getElementById('google-pay')?.remove();
                }
            })
            .catch((err: any) => {
                // show error in developer console for debugging
                //console.error(err);
            });
    }


    getGooglePaymentDataRequest() {
        const paymentDataRequest: any = Object.assign({}, this.config.baseRequest);
        paymentDataRequest.allowedPaymentMethods = [this.cardPaymentMethod];
        paymentDataRequest.transactionInfo = this.getGoogleTransactionInfo();

        let companyName = this.CompanyInfoStore.Name;
        if (companyName && this.CommonStore.Language)
            companyName = `עבור ${companyName}`;

        paymentDataRequest.merchantInfo = {
            // @todo a merchant ID is available for a production environment after approval by Google
            // See {@link https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist|Integration checklist}
            merchantId: 'BCR2DN4TXKC4TGLV',
            merchantName: companyName
        };

        paymentDataRequest.callbackIntents = ['PAYMENT_AUTHORIZATION'];

        return paymentDataRequest;
    }

    onGooglePaymentButtonClicked() {
        const paymentDataRequest = this.getGooglePaymentDataRequest();
        paymentDataRequest.transactionInfo = this.getGoogleTransactionInfo();
        const data = JSON.parse(JSON.stringify(paymentDataRequest));
        const paymentsClient = this.getGooglePaymentsClient();
        paymentsClient
            .loadPaymentData(data)
            .catch(function (err: any) {
                // show error in developer console for debugging
                //console.error(err);
            });
    }

}




export { GooglePayManager }
export default {
    m: {
        hello: 'שלום בידקה',
        merchantInfo: "פרטי העסק",
        Email: 'דוא״ל',
        Address: 'כתובת',
        AddToCart: 'הוספה',
        Continue: 'המשך',
        YourOrder: 'ההזמנה שלך',
        YourDonation: 'התרומה שלך',
        YourCartIsEmpty: 'העגלה שלך ריקה',
        YouGotDiscount: 'קיבלת {msg} הנחה',
        CouponActivated: 'קופון הופעל בהצלחה',
        RemoveCoupon: 'הסר קופון',
        YouStillDidNotOrder: 'עדיין לא הזמנת...',
        HaveACoupon: 'יש לך קופון?',
        ValidateCoupon: 'מימוש',
        TotalBeforDiscountOrder: 'סה"כ לפני הנחות',
        CouponDiscount: 'הנחת קופון',
        TotalOrder: 'סה"כ',
        Details: 'לפירוט',
        DetailsAndCoupon: 'לפירוט ומימוש קופון',
        CartItemsPart1_Total: 'סה״כ',
        CartItemsPart2_Items: 'פריטים',
        CardNumber: 'מספר כרטיס',
        CardOwnerName: 'שם בעל הכרטיס',
        NumberOfPayments: 'מספר תשלומים',
        NumberOfCharges: 'מספר חיובים',
        CardValidityYear: 'תוקף שנה',
        CardValidityMonth: 'תוקף חודש',
        Cvv: 'CVV',
        CardTZ: 'ת.ז. בעל/ת הכרטיס',
        ApprovedTermsAndConditions: 'אני מאשר/ת את תנאי השימוש',
        AllowMerchantMessages: 'אני מאשר/ת קבלת דיוור',
        loading: 'אנא המתן...',
        DeliveryMethod: "אופן קבלת ההזמנה",
        FullAddress: "כתובת מלאה כולל מספר בית",
        City: "עיר",
        Street: "רחוב",
        Building: "בית",
        Apartment: "דירה",
        Floor: "קומה",
        Entrance: "כניסה",
        DeliveryNotes: "הערות לשליח",
        FixInputErrors: "נא לתקן את השדות המסומנים",
        CreditCard: "כרטיס אשראי",
        PciNote: "הסליקה מתבצעת באמצעות קארדקום בתקן PCI",
        Donation: "תרומה",
        SingleTimeDonation: "תרומה חד פעמית",
        MonthlyDonation: "תרומה חודשית",
        RecurringDirectDebit: 'הוראת קבע מתחדשת',
        OneTimeDonation: 'תרומה חד פעמית',
        SingleCharge: 'חיוב בודד',
        DonationInMultipleCharges: 'תרומה במספר חיובים',
        NumberOfChargesByChoice: 'מספר חיובים לפי בחירה',
        SelectANumberOfCharges: 'בחירת מספר חיובים',
        DonationSumDescription: '[NumOfPayments] חיובים של [TotalSum][CurrencySign]',
        DonationRegularPaymentsDescription: '[TotalSum][CurrencySign] ב-[NumOfPayments] תשלומים',
        MissingNumberOfChargesError: 'אנא בחר כמות חיובים',
        DocumentDetails: "פרטים לחשבונית מס קבלה",
        DocumentEmail: "דואר אלקטרוני",
        DocumentEmailConfirm: "אימות דואר אלקטרוני",
        DocumentName: "לכבוד",
        DocumentTZ: "תעודת זהות/ח.פ",
        DocumentMobile: "טלפון נייד",
        DocumentAdditionalPhone: "טלפון נוסף",
        DocumentCity: "ישוב",
        DocumentStreet: "רחוב",
        DocumentZipcode: "מיקוד/ת.ד",
        InvoiceInfo: "פרטים לחשבונית",
        Confirm: 'אישור',
        Cancel: 'ביטול',
        ErrorTitle: 'שגיאה',
        RecaptchaError: 'יש לסמן אני לא רובוט',
        TermsAndConditionsError: 'יש לאשר את תנאי השימוש',
        MerchantMessagesError: 'רכישה זו מצריכה את אישורכם לקבלת דיוור מאת בית העסק',
        SubmitPaymentError: 'אירעה שגיאה בשליחת התשלום',
        PayPalPaymentError: 'אירעה שגיאה בבניית בקשת תשלום ב-PayPal',
        BitPaymentError: 'אירעה שגיאה בבניית בקשת תשלום ב-BIT',
        GeneralError: 'אירעה שגיאה לא צפוייה',
        TimeoutError: 'פג תוקף הדף',
        TimeoutErrorMessage: 'אין אפשרות להציג דף זה מכיוון שפג תוקפו',
        DataLoadError: 'אירעה שגיאה בטעינת הנתונים',
        CardOwnerNameError: 'שם בעל הכרטיס לא תקין',
        PageNotFoundError: 'כתובת לא קיימת',
        OnePaymentOnlyError: 'אמצעי תשלום זה תומך בתשלום אחד בלבד, נא לשנות את מספר התשלומים לאחד',
        PaymentFail: 'החיוב נכשל',
        CouponOutOfStock: 'אזל מלאי הקופונים',
        CardNotSupported: 'כרטיס לא נתמך, נא להחליף',
        BitTransactionLimit: 'חיוב דרך ביט מוגבל ל-₪5,000 בלבד. אנא ביחרו באפשרות תשלום אחרת',
        BitTransactionMinimumLimit: 'חיוב דרך ביט נתמך רק מ-₪1 בלבד. אנא ביחרו באפשרות תשלום אחרת.',
        CouponErrorTitle: 'שגיאה במימוש קופון',
        UnableToApplyCoupon: 'אין אפשרות לממש קופון',
        CouponsNotActive: 'אין קופונים פעילים',
        InvalidCouponCode: 'קוד קופון לא תקין',
        CouponNotExist: 'קופון לא קיים',
        CouponExpired: 'קופון פג תוקף',
        RequiredField: 'שדה חובה',
        InvalidEmailAddress: 'כתובת דוא"ל לא תקינה',
        InvalidPhoneNumber: 'מספר טלפון לא תקין',
        NumbersOnly: 'ספרות בלבד',
        InvalidCardNumber: 'מספר כרטיס לא תקין',
        InvalidCvv: 'לא תקין CVV',
        InvalidInputMatch: 'מייל בדיקה לא זהה',
        InvalidNumber: 'מספר לא תקין',
        DebiteMontly: 'חודשי',
        NoItemsInGroup: 'קבוצה ללא פריט - יש להקים פריט',
        ItemSumAsParameter_ItemNoMatch: 'להעברת סכום כפרמטר יש להגדיר את הדף עם פריט אחד בודד והפריט צריך להיות מוגדר סכום פתוח',
        ItemSumAsParameter_InvalideParam: 'יש לוודא תקינות פרמטרים שהועברו',
        ItemOutOfStock: 'הפריטים הנבחרים אזלו במלאי',
        EndOfInventory: 'נגמר המלאי',
        CustomField_CompanyName: 'שם חברה',
        CustomField_CompanyType: 'בחר סוג העסק',
        CustomField_ContactName: 'שם איש קשר',
        CustomField_SapakAshrai: 'ספק אשראי',
        CustomField_SapakNumber: 'מספר ספק (אם יש)',
        CustomField_Recommend: 'ממליץ / חברת אתרים',
        DropDownChooseField: 'בחר',
        CustomField_ExamptDealer: 'עוסק פטור',
        CustomField_LicensedDealer: 'עוסק מורשה',
        CustomField_CompanyLtd: 'חברה בע"מ',
        CustomField_NonProfit: 'מלכ"ר',
        CustomField_AMUTA: 'עמותה',
        CustomField_Other: 'אחר',
        TitleIntro: 'הקדמה',
        TitlePurchase: 'פרטי עסקה',
        TitleDonation: 'פרטי תרומה',
        TitleRegistration: 'פרטי הרשמה',
        TitleDelivery: 'פרטי משלוח',
        TitleInvoice: 'פרטים לחשבונית',
        TitleReceiptMalkar: 'פרטים לקבלה',
        TitleOrder: 'פרטים להזמנה',
        TitleInvoiceReceipt: 'פרטים לקבלה',
        TitleDonationReceipt: 'פרטים לקבלה על תרומה',
        TitleAdditionalDetails: 'פרטים נוספים',
        TitlePayment: 'אמצעי תשלום',
        PayWithCreditCard: 'תשלום באשראי',
        PayWithPayPal: 'תשלום בפייפאל',
        PayWithBit: 'תשלום בביט',
        PayWithApplePay: 'תשלום באפל פיי',
        PayWithGooglePay: 'תשלום בגוגל פיי',
        SubmitButton: 'סיים',
        SubmitButtonPurchase: 'בצע/י תשלום',
        SubmitButtonDonation: 'בצע תרומה',
        SubmitButtonRegistration: 'הירשם',
        SuccessTitle: 'עסקה בוצעה בהצלחה',
        SuccessMessage: 'לקוח נכבד, העסקה בוצעה בהצלחה',
        SuccessAndTransferMessage: 'העסקה הושלמה בהצלחה, מייד תועברו לעמוד הבא',
        SuccessConfirmMessage: 'ניתן לשמור את מספר האישור לשם בירורים מול בית העסק',
        SuccessConfirmNumber: 'מספר העסקה',
        FailTitle: 'עסקה נכשלה',
        PaymentFor: 'תשלום עבור',
        Quantity: 'כמות',
        ApplePaySetup: 'הגדר כרטיס אשראי',
        ApplePayKeepOpen: 'השאירו חלונית זו מוצגת בעת אישור התשלום במכשירכם',
        ApplePayPopUp: 'שימו לב: הפעולה עשויה לפתוח דף חדש',
        PaymentsCommission: 'עמלת תשלומים',
        OpenSumTitle: 'סכום לתשלום',
        OpenSumDonationTitle: 'סכום תרומה',
        InvalidOpenSum: 'מספרים חיוביים בלבד',
        ThreeDSecureErrorTitle: 'שגיאת 3DS',
        AuthRejected: 'אימות סורב',
        AuthFailed: 'אימות נכשל',
        ManualSum: 'סכום פתוח',
        TotalRequireVat: 'סה"כ חייב במע"מ',
        TotalNotRequireVat: 'סה"כ לא חייב במע"מ',
        TotalVat: 'סה"כ מע"מ',
        NotSupportedMasterCard: 'בית העסק אינו מכבד כרטיסי מאסטרקארד. אנא החלף כרטיס',
        NotSupportedVisa: 'בית העסק אינו מכבד כרטיסי ויזה. אנא החלף כרטיס',
        NotSupportedAmex: 'בית העסק אינו מכבד כרטיסי אמריקן אקספרס. אנא החלף כרטיס',
        NotSupportedDiners: 'בית העסק אינו מכבד כרטיסי דיינרס. אנא החלף כרטיס',
        InvalidNumberOfPayments: 'מספר תשלומים לא תואם לדרישות המערכת',
        UserIDReq: 'ת.ז. שדה חובה',
        InvalidCardExpiration: 'תוקף כרטיס שגוי',
        CardOwnerCity: 'עיר בעל הכרטיס',
        CardOwnerAddress: 'כתובת בעל הכרטיס',
        CardOwnerZipcode: 'מיקוד בעל הכרטיס',
        ForDealCompletionClickOnButton: 'להשלמת העסקה יש ללחוץ על כפתור "[[ButtonName]]"',
        GlobalCaptchaMissingToken: 'שגיאת אימות נתונים, יש לרענן את הדף ולנסות שנית',
        GPayAdditionalDetailsTitle: "פרטים אחרונים לפני שמשלמים",
        GPayAdditionalDetailsDescription: "נשאר רק להזין את השדות שבכוכבית וללחוץ על הכפתור לתשלום",
        backToCreditCardPaymentTitle: "להחלפת אמצעי תשלום",
        UPayBitFail: "עסקת ביט לא הושלמה בפרק הזמן הקצוב או נכשלה",
        UpayId: "מזהה חיצוני",
        CardcomDealNumber: "מספר עסקה קארדקום",
        TransactionAmount: "סכום עסקה",
        PaymentPage: "דף תשלום",
        BusinessName: "שם העסק",
        BusinessContactDetails: "פרטי העסק ליצירת קשר",
        UpayBitLastDetails: 'פרטים אחרונים לפני שמשלמים',
        UpayBitFillCardOwnerName: 'יש למלא את שם בעל הכרטיס ולאחר מכן ללחוץ על הכפתור לתשלום באפליקציית ביט',
        PayOnBitApp: 'תשלום באפליקציית ביט',
        BitScan: 'יש לסרוק את הברקוד על מנת להמשיך את תהליך התשלום',
        BitClick: 'יש ללחוץ על כפתור ביט על מנת להמשיך את תהליך התשלום',
        BitCompleteInApp: 'יש להמשיך את התהליך באפליקציית ביט',
        BitConfirmInApp: 'יש לאשר את התשלום באפליקציית ביט תוך',
        BitMinutes: 'דקות',
        LastDetailsBeforePay: 'פרטים אחרונים לפני שמשלמים',
        ApplePayFillCardOwnerName: 'יש למלא את שם בעל הכרטיס ולאחר מכן ללחוץ על הכפתור לתשלום באמצעות Apple Pay',
        ClickToPayWithApplePay: 'תשלום באמצעות Apple Pay',
        CardOwnerNameReq: 'יש למלא שם בעל הכרטיס',
        TitleDonationType: 'סוג התרומה',
        TitleDonationAmount: 'סכום התרומה',
        MonthlyDonationShort: 'חודשית',
        RecurringDirectDebitShort: 'הוראת קבע מתחדשת',
        OneTimeDonationShort: 'חד פעמית',
        ByChoice: 'לפי בחירה',
        SelectANumberOfPayments: 'בחירת מספר תשלומים',
        TitleOrderSummary: 'סיכום ההזמנה שלך',
        TitleDonationSummary: 'סיכום התרומה שלך',
        NoProductsSelected: 'יש לבחור לפחות מוצר אחד לרכישה',
        GooglePayFillCardOwnerName: 'יש למלא את שם בעל הכרטיס ולאחר מכן ללחוץ על הכפתור לתשלום באמצעות Google Pay',
        ClickToPayWithGooglePay: 'תשלום באמצעות Google Pay',
        ClickToPayWithPayPal: 'תשלום באמצעות PayPal',
        Delivery: 'משלוח',
        ShowInDifferentCurrency: 'הצג מחירים במטבע אחר',
        Info: 'פירוט',
        PciBy: 'הסליקה מתבצעת באמצעות חברת',
        PciTitle: 'התשלום מאובטח באופן מלא ובתקן המחמיר ביותר',
        OtherSum: 'אחר',
        PhoneOrEmailRequired: 'חובה למלא טלפון או אימייל',
        PageNotExistErrorTitle: 'אופס, הגעתם לדף תשלום שכבר לא קיים',
        PageNotExistErrorMessage: 'נסו לחפש דרך אחרת כדי להגיע לעמוד המבוקש',
        PaymentFailedRetryOrOtherMethod: 'התשלום נכשל, ניתן לנסות שוב או לבחור באמצעי תשלום אחר',
        PaymentFailedNoAmexOrDinersAllow: 'בית העסק לא מכבד כרטיסי אשראי מסוג אמריקן אקספרס או דיינרס, נסו להשתמש במותג אחר',
        PaymentCanceledByCustomer: 'התשלום בוטל על ידי הלקוח',
        PaymentRequestExpired: 'פג תוקף הבקשה, יש לבצע תשלום מחדש',
        TransactionMadeButChargeFailed: 'בוצעה עסקה אך החיוב נכשל',
        MobileBrowserPopupError: 'יש לבטל חסימה של חלונות קופצים בהגדרות הדפדפן',
        ClickToOpenBitApplication: 'לחצו כאן כדי לפתוח את אפליקציית ביט להשלמת התהליך',
        ClickBitButtonToOpenBitApplication: 'לחצו על כפתור תשלום בביט לפתיחת האפליקציה',
        ClickToChooseDifferentPaymentMethod: 'לבחירת אמצעי תשלום אחר',
        CreditPayments: 'תשלומי קרדיט',
        TitlePaymentWithCreditCard: 'תשלום בכרטיס אשראי',
    },
    currencies: {
        ILS: 'ש"ח',
        AUD: 'אוסטרלי',
        CAD: 'דולר קנדי',
        CNY: 'ין סיני',
        DKK: 'כתר דנמרק',
        HKD: 'דולר הונג קונג',
        JPY: 'ין יפני',
        MXN: 'פסו',
        NZD: 'דולר ניוזלנדי',
        RUB: 'רובל רוסי',
        SGD: 'דולר סינגפורי',
        SEK: 'קרונה שבדית',
        CHF: 'פרנק שוויץ',
        GBP: 'ליש"ט',
        USD: 'דולר',
        EUR: 'יורו'
    },
    footer: {
        cardcom: 'קארדקום בע"מ',
        createBy: 'דף זה נוצר באמצעות',
        noResponsibility: 'קארדקום מספקת פתרונות סליקה בלבד ואינה אחראית למוצרים, לשירותים, לזמני האספקה או להתקשרות בין בית העסק ללקוחותיו'
    },
    ps: {
        Intro: 'הקדמה',
        Order: 'ההזמנה שלך',
        Donation: 'התרומה שלך',
        Delivery: 'משלוח',
        Document: 'מסמך',
        Details: 'פרטים נוספים',
        Payment: 'תשלום',
        Summery: 'סיום',

        IntroAlt: 'מסך הקדמה',
        OrderAlt: 'מסך ההזמנה שלך',
        DonationAlt: 'מסך התרומה שלך',
        DeliveryAlt: 'מסך משלוח',
        DocumentAlt: 'מסך מסמך',
        DetailsAlt: 'מסך פרטים נוספים',
        PaymentAlt: 'מסך תשלום',
        SummeryAlt: 'מסך סיום'
    },
    css: {
        htmlDir: 'rtl',
    }
}
import { Product } from "../Store/ProductSelection"
import { createPinia } from 'pinia'
import { useDeliveryStore } from "../Store/DeliveryStore";
import { useDocumentStore } from "../Store/DocumentStore";
import { usePaymentStore } from "../Store/PaymentStore";
import { SelectKeyValue } from '../components/input/InputSelect.vue'

const pinia = createPinia();
const DeliveryStore = useDeliveryStore(pinia);
const DocumentStore = useDocumentStore(pinia);
const PaymentStore = usePaymentStore(pinia);


const ProductUtils = {

    CheckSpecialPrice(product: Product) {
        if (product != undefined && product != null) {
            if (product.IsManualSum)
                return;

            if (product.SpecialPrices != undefined && product.SpecialPrices != null) {
                const specialPrice = product.SpecialPrices.find(sp => product.SelectedBuyQuantity >= sp.QuantityFrom && product.SelectedBuyQuantity <= sp.QuantityTo);
                if (specialPrice != undefined) {
                    product.PriceToShow = specialPrice.Cost;
                }
                else {
                    product.PriceToShow = product.BasePrice;
                }
            }
        }
    }

}

const PriceUtils = {

    GetNumberWithCommas(number: number, toFixed: boolean = false) {
        number = Math.round(number * 100) / 100;
        let numberS = number.toFixed(2);
        const isWholeNumber = (number % 1 == 0);
        if (isWholeNumber && !toFixed)
            numberS = number.toString();

        return numberS.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

}

const FormUtils = {

    GetName() {
        let name = '';

        // try to get name from credit card form
        if (PaymentStore != null && PaymentStore != undefined)
            if (PaymentStore.CardOwnerNameField != null && PaymentStore.CardOwnerNameField != undefined)
                if (PaymentStore.CardOwnerNameField.Value != null && PaymentStore.CardOwnerNameField.Value != undefined)
                    name = PaymentStore.CardOwnerNameField.Value.trim();

        // try to get name from invoice form
        if (name == '') {
            if (DocumentStore != null && DocumentStore != undefined) {
                const documentNameField = DocumentStore.FormFields.find(field => field.Ref == 'DocumentName');
                if (documentNameField != null && documentNameField != undefined)
                    if (documentNameField.Value != null && documentNameField.Value != undefined)
                        name = documentNameField.Value.trim();
            }
        }

        return name;
    },

    GetEmail() {
        let email = '';

        // try to get email from invoice form
        if (DocumentStore != null && DocumentStore != undefined) {
            const documentEmailField = DocumentStore.FormFields.find(field => field.Ref == 'DocumentEmail');
            if (documentEmailField != null && documentEmailField != undefined)
                if (documentEmailField.Value != null && documentEmailField.Value != undefined)
                    email = documentEmailField.Value.trim();
        }

        // try to get email from credit card form
        if (email == '') {
            if (PaymentStore != null && PaymentStore != undefined)
                if (PaymentStore.EmailField != null && PaymentStore.EmailField != undefined)
                    if (PaymentStore.EmailField.Value != null && PaymentStore.EmailField.Value != undefined)
                        email = PaymentStore.EmailField.Value.trim();

        }

        return email;
    },

    GetMobilePhone() {
        let mobilePhone = '';

        // try to get mobile number from invoice form
        if (DocumentStore != null && DocumentStore != undefined) {
            const documentMobileField = DocumentStore.FormFields.find(field => field.Ref == 'DocumentMobile');
            if (documentMobileField != null && documentMobileField != undefined)
                if (documentMobileField.Value != null && documentMobileField.Value != undefined)
                    mobilePhone = documentMobileField.Value.trim();
        }

        // try to get mobile number from credit card form
        if (mobilePhone == '') {
            if (PaymentStore != null && PaymentStore != undefined)
                if (PaymentStore.MobileField != null && PaymentStore.MobileField != undefined)
                    if (PaymentStore.MobileField.Value != null && PaymentStore.MobileField.Value != undefined)
                        mobilePhone = PaymentStore.MobileField.Value.trim();
        }

        return mobilePhone;
    },

    GetCity() {
        let city = '';

        const deliveryCityField = DeliveryStore.CityField;
        if (deliveryCityField != null && deliveryCityField != undefined)
            if (deliveryCityField.Value != null && deliveryCityField.Value != undefined)
                city = deliveryCityField.Value.trim();

        if (city == '') {
            const documentCityField = DocumentStore.FormFields.find(field => field.Ref == 'DocumentCity');
            if (documentCityField != null && documentCityField != undefined)
                if (documentCityField.Value != null && documentCityField.Value != undefined)
                    city = documentCityField.Value.trim();
        }

        return city;
    },

    GetStreetAndNumber() {
        let street = '';
        let _streetName = '';
        let _streetNumber = '';

        const deliveryStreetField = DeliveryStore.StreetField;
        if (deliveryStreetField != null && deliveryStreetField != undefined)
            if (deliveryStreetField.Value != null && deliveryStreetField.Value != undefined)
                _streetName = deliveryStreetField.Value.trim();

        const deliveryBuildingNumberField = DeliveryStore.BuildingNumberField;
        if (deliveryBuildingNumberField != null && deliveryBuildingNumberField != undefined)
            if (deliveryBuildingNumberField.Value != null && deliveryBuildingNumberField.Value != undefined)
                _streetNumber = deliveryBuildingNumberField.Value;

        const _streetAndNumber = `${_streetName} ${_streetNumber}`.trim();
        if (_streetAndNumber != '')
            street = _streetAndNumber;

        if (street == '') {
            const documentStreetField = DocumentStore.FormFields.find(field => field.Ref == 'DocumentStreet');
            if (documentStreetField != null && documentStreetField != undefined)
                if (documentStreetField.Value != null && documentStreetField.Value != undefined)
                    street = documentStreetField.Value.trim();
        }

        return street;
    },

    GetZipCode() {
        let zipcode = '';

        const documentZipcodeField = DocumentStore.FormFields.find(field => field.Ref == 'DocumentZipcode');
        if (documentZipcodeField != null && documentZipcodeField != undefined)
            if (documentZipcodeField.Value != null && documentZipcodeField.Value != undefined)
                zipcode = documentZipcodeField.Value.trim();

        return zipcode;
    },

    GetCreditCardBrand(cardNumber: string) {
        if (!cardNumber)
            return 0; // Unknown

        const brandsRE = {
            mastercard: /^5[1-5][0-9]{14}$/,
            visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
            maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
            amex: /^3[47][0-9]{13}$/,
            jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
            discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
            diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/
        }

        if (brandsRE.mastercard.test(cardNumber)) // MasterCard
            return 1;
        if (brandsRE.visa.test(cardNumber)) // Visa
            return 2;
        if (brandsRE.maestro.test(cardNumber)) // Maestro
            return 3;
        if (brandsRE.amex.test(cardNumber)) // Amex
            return 4;
        if (brandsRE.jcb.test(cardNumber)) // JCB
            return 6;
        if (brandsRE.discover.test(cardNumber)) // Discover
            return 7;
        if (brandsRE.diners.test(cardNumber)) // Diners
            return 8;

        return 0; // Unknown
    },
}

const CommonUtils = {

    IsShvaError(errorCode: number) {
        if (errorCode >= 60000000)
            errorCode = errorCode - 60000000;

        // חוסר התאמהת נתונים בין המסוף למרכז
        if (errorCode > 0 && errorCode <= 99)
            return true;
        // שגיאות ב-STANDIN
        else if (errorCode >= 100 && errorCode <= 159)
            return true;
        // בדיקת זיכוי סולק
        else if (errorCode >= 160 && errorCode <= 164)
            return true;
        // שגיאות הרשאת סולק - סוג עסקה
        else if (errorCode >= 220 && errorCode <= 230)
            return true;
        // שגיאות הרשאת סולק - סוג אשראי
        else if (errorCode >= 250 && errorCode <= 260)
            return true;
        // שגיאות הרשאות לסוג אשראי לעסקה
        else if (errorCode >= 300 && errorCode <= 310)
            return true;
        // שגיאות הרשאת סולק לסוג אשראי
        else if (errorCode >= 350 && errorCode <= 360)
            return true;
        // שגיאות הרשאת סולק - סוג מטבע
        else if (errorCode >= 400 && errorCode <= 410)
            return true;
        // שגיאות הרשאות סולק כאל
        else if (errorCode >= 420 && errorCode <= 430)
            return true;
        // שגיאות הרשאות סולק ישראכרט ב-STANDIN
        else if (errorCode >= 440 && errorCode <= 449)
            return true;
        // בדיקות סכומי סולק
        else if (errorCode >= 450 && errorCode <= 459)
            return true;
        // שגיאות הרשאת סולק ל-EMV
        else if (errorCode >= 460 && errorCode <= 470)
            return true;
        // שגיאות הרשאות סולק לאומי קארד
        else if (errorCode >= 480 && errorCode <= 490)
            return true;
        // שגיאות חוקיות מסר
        else if (errorCode >= 500 && errorCode <= 530)
            return true;
        // שגיאות הרשאות מנפיק -STANDIN
        else if (errorCode >= 600 && errorCode <= 620)
            return true;
        // שגיאות תהליך EMV
        else if (errorCode >= 700 && errorCode <= 730)
            return true;
        // שגיאות בתהליך ה-BATCH
        else if (errorCode >= 1000 && errorCode <= 1100)
            return true;
        // שגיאות כלליות
        else if (errorCode >= 2000 && errorCode <= 2100)
            return true;
        else
            return false;

    },

    IsInternalUrl(url: string) {
        if (url == undefined || url == null || url.trim() == '')
            return false;

        const skipRedirectAddresses = ['secure.cardcom.solutions', 'secure.cardcom.co.il'];
        const match = skipRedirectAddresses.find(a => url.trim().toLowerCase().indexOf(a) != -1);
        return match != undefined;
    }

}

export { ProductUtils, PriceUtils, FormUtils, CommonUtils }
import { SelectKeyValue } from "./InputSelect.vue";
import { Validator } from "./Validator";

export class FormField {
    Ref = '';
    Id = 0;
    Order = 0;
    InputType = InputType.Unknown;
    Required = false;
    PreventPaste = false;
    Show = true;
    Data = new FormFieldData();
    Value = '';
    Direction = LayoutDirection.RTL;
}

export class FormFieldData {
    Label = '';
    validators = Array<Validator>();
    SelectList = Array<SelectKeyValue>();
}

export enum InputType {
    Unknown = 0,
    InputText = 1,
    InputSelect = 2,
    InputNumber = 3
}

export enum LayoutDirection {
    RTL = 0,
    LTR = 1
}
/// https://router.vuejs.org/guide/essentials/history-mode.html#internet-information-services-iis
import { useRouter, useRoute, createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import ErrorPage from './components/Error.vue'
import SuccessPage from './components/Success.vue'
import FailPage from './components/Fail.vue'
import PageNotFound from './components/NotFound.vue'
import EA5 from './EA5/EA5.vue'
import Intro from './EA5/IntroPage.vue'
import ProductsSelection from './EA5/ProductsSelection.vue'
import Delivery from './EA5/DeliveryPage.vue'
import Document from './EA5/DocumentPage.vue'
import Details from './EA5/DetailsPage.vue'
import Payment from './EA5/PaymentPage.vue'
import Donation from './EA5/DonationPage.vue'
import LPC6Payment from './LPC6/Payment.vue'
import uPayFailPage from './components/uPay/Fail.vue'
import TimeoutPage from './components/Timeout.vue'
import PaymentSinglePage from './EA5/Payment.vue'
import NotExistPage from './components/NotExist.vue'

// http://localhost:8080/EA5/y/order

const routes: RouteRecordRaw[] = [
    {
        path: '/EA/Error/',
        name: 'Error',
        component: ErrorPage,
        props: true
    },
    {
        path: '/EA/Success/',
        name: 'Success',
        component: SuccessPage,
        props: true
    },
    {
        path: '/EA/Fail/',
        name: 'Fail',
        component: FailPage,
        props: true
    },
    {
        path: '/EA/Timeout/',
        name: 'Timeout',
        component: TimeoutPage,
        props: true
    },
    {
        path: '/EA/uPay/Fail/',
        name: 'uPayFail',
        component: uPayFailPage,
        props: true
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotExist',
        component: NotExistPage,
        props: true
    },
    {
        path: '/EA/EA5/:UID',
        name: 'EA5',
        component: EA5,
        props: true,
        children: [
            { path: 'Intro', component: Intro, name: "IntroPage", props: true },
            { path: 'Order', component: ProductsSelection, name: "Order", props: true },
            { path: 'Donation', component: Donation, name: "DonationPage", props: true },
            { path: 'Delivery', component: Delivery, name: "DeliveryPage", props: true },
            { path: 'Document', component: Document, name: "DocumentPage", props: true },
            { path: 'Details', component: Details, name: "DetailsPage", props: true },
            { path: 'Payment', component: Payment, name: "PaymentPage", props: true },
            { path: 'PaymentSP', component: PaymentSinglePage, name: "PaymentSinglePage", props: true }
        ]
    },
    {
        path: '/EA/LPC6/:TerminalNumber/:LowProfileCode',
        name: 'LPC6Payment',
        component: LPC6Payment,
        props: true,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: PageNotFound
    }
]

export const myRouter = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

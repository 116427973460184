import { usePageSelectionEA5 } from '../Store/PageSelectionEA5'
import { useCompanyInfoStore } from "../Store/CompanyInfoStore";
import { useDeliveryStore } from "../Store/DeliveryStore";
import { useDetailsStore } from "../Store/DetailsStore";
import { useDocumentStore } from "../Store/DocumentStore";
import { Cardcom3DSecureResponse, usePaymentStore } from "../Store/PaymentStore";
import { useProductsStore } from "../Store/ProductSelection";
import { useCommonStore } from "../Store/CommonStore";
import { useI18n } from 'vue-i18n'
import { myRouter } from '../RouterMy';
import { i18nConfig } from '../translate';




const StoreService = {

    LoadDataEA5(uid: string, queryString: string) {

        const { t, te } = useI18n();

        const apiUrl = '/api/EA5/Load'; // 'api/LPC6/Load'


        const request = {
            Store: {
                CommonStore: {
                    Uid: uid
                }
            },
            Query: queryString,
            UserAgent: navigator.userAgent
        };
        const data = JSON.stringify(request);
        return fetch(`${process.env.VUE_APP_ROOT_API}${apiUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        }).then(response => response.json()).
            then((response) => {

                const CommonStore = useCommonStore();

                if (response.IsRedirect) {
                    CommonStore.StoreInitialized = true;
                    CommonStore.IsError = response.IsError;
                    CommonStore.ErrorMessage = response.Message;

                    if (response.RedirectUrl != null && response.RedirectUrl != undefined && response.RedirectUrl.trim() != '') {
                        window.location.href = response.RedirectUrl;
                    }

                    return;
                }

                const language = response.Language.toLowerCase();

                document.querySelector('html')?.setAttribute("lang", language);

                i18nConfig.global.locale.value = language
                const direction = i18nConfig.global.t('css.htmlDir');
                const css = `/EA6/style${direction}.css?v=${process.env.VUE_APP_VER}`;
                document.querySelector('link[name="css-direction"]')?.setAttribute("href", css);

                if (response.IsError) {

                    CommonStore.IsError = true;
                    CommonStore.ErrorCode = response.ErrorCode;

                    if (response.Message != null && response.Message != undefined) {
                        CommonStore.ErrorMessage = response.Message;

                        if (te(response.Message)) {
                            CommonStore.ErrorMessage = t(response.Message);
                        }
                    }

                    return;
                }

                if (response.Store == null || response.Store == undefined) {
                    CommonStore.IsError = true;

                    return;
                }

                // init PageSelectionStore
                const PageSelectionStore = usePageSelectionEA5();

                // init CompanyInfoStore
                const CompanyInfoStore = useCompanyInfoStore();
                if (response.Store.CompanyInfoStore != undefined && response.Store.CompanyInfoStore != null) {
                    CompanyInfoStore.CompanyNumber = response.Store.CompanyInfoStore.CompanyNumber;
                    CompanyInfoStore.Name = response.Store.CompanyInfoStore.Name;
                    //document.title = CompanyInfoStore.Name; => Not relevant, will use Vue-Head plugin to set meta tags
                    CompanyInfoStore.Address1 = response.Store.CompanyInfoStore.Address1;
                    CompanyInfoStore.Address2 = response.Store.CompanyInfoStore.Address2;
                    CompanyInfoStore.Address3 = response.Store.CompanyInfoStore.Address3;
                    CompanyInfoStore.Tel = response.Store.CompanyInfoStore.Tel;
                    CompanyInfoStore.Email = response.Store.CompanyInfoStore.Email;
                    CompanyInfoStore.LogoURL = response.Store.CompanyInfoStore.LogoURL;
                    CompanyInfoStore.LogoAlt = response.Store.CompanyInfoStore.LogoAlt;
                    CompanyInfoStore.YoutubeLink = response.Store.CompanyInfoStore.YoutubeLink;
                    CompanyInfoStore.InstagramLink = response.Store.CompanyInfoStore.InstagramLink;
                    CompanyInfoStore.FacebookLink = response.Store.CompanyInfoStore.FacebookLink;
                    CompanyInfoStore.WhatsappLink = response.Store.CompanyInfoStore.WhatsappLink;
                    CompanyInfoStore.Comments = response.Store.CompanyInfoStore.Comments;
                    CompanyInfoStore.ShowGPayLogo = response.Store.CompanyInfoStore.ShowGPayLogo;
                    CompanyInfoStore.ShowMasterCardLogo = response.Store.CompanyInfoStore.ShowMasterCardLogo;
                    CompanyInfoStore.ShowVisaLogo = response.Store.CompanyInfoStore.ShowVisaLogo;
                    CompanyInfoStore.ShowAmexLogo = response.Store.CompanyInfoStore.ShowAmexLogo;
                    CompanyInfoStore.ShowDinersLogo = response.Store.CompanyInfoStore.ShowDinersLogo;
                    CompanyInfoStore.ShowIsracardLogo = response.Store.CompanyInfoStore.ShowIsracardLogo;
                    CompanyInfoStore.MetaTitle = response.Store.CompanyInfoStore.MetaTitle;
                    CompanyInfoStore.MetaDescription = response.Store.CompanyInfoStore.MetaDescription;
                    CompanyInfoStore.MetaImage = response.Store.CompanyInfoStore.MetaImage;
                    CompanyInfoStore.GoogleAnalyticsCodeOne = response.Store.CompanyInfoStore.GoogleAnalyticsCodeOne;
                }

                // init ProductsStore
                const ProductsStore = useProductsStore();
                if (response.Store.ProductsStore != undefined && response.Store.ProductsStore != null) {
                    PageSelectionStore.ShowOrder = !response.Store.ProductsStore.IsDonation;
                    PageSelectionStore.ShowDonation = response.Store.ProductsStore.IsDonation;

                    ProductsStore.Title = response.Store.ProductsStore.Title;
                    ProductsStore.isDonation = response.Store.ProductsStore.IsDonation;
                    ProductsStore.showCurrencyConvert = response.Store.ProductsStore.ShowCurrencyConvert;
                    ProductsStore.currencies = response.Store.ProductsStore.Currencies;
                    ProductsStore.selectedCurrency = response.Store.ProductsStore.SelectedCurrency;
                    ProductsStore.couponCalculatedDiscount = response.Store.ProductsStore.CouponCalculatedDiscount;
                    ProductsStore.products = response.Store.ProductsStore.Products;
                    ProductsStore.deliveryMethods = response.Store.ProductsStore.DeliveryMethods;
                    ProductsStore.haveImages = response.Store.ProductsStore.HaveImages;
                    ProductsStore.isCouponActive = response.Store.ProductsStore.IsCouponActive;
                    ProductsStore.ShowProductPrice = response.Store.ProductsStore.ShowProductPrice;
                    ProductsStore.ShowTotalPrice = response.Store.ProductsStore.ShowTotalPrice;
                    ProductsStore.ItemsDisplayType = response.Store.ProductsStore.ItemsDisplayType;
                    ProductsStore.ManualSumField = response.Store.ProductsStore.ManualSumField;
                    ProductsStore.ShowVatInfo = response.Store.ProductsStore.ShowVatInfo;
                    ProductsStore.Vat = response.Store.ProductsStore.Vat;
                    ProductsStore.ShowDonationAmountsButtons = response.Store.ProductsStore.ShowDonationAmountsButtons;
                    ProductsStore.DonationDescription = response.Store.ProductsStore.DonationDescription;
                }

                // init DeliveryStore
                const DeliveryStore = useDeliveryStore();
                if (response.Store.DeliveryStore != undefined && response.Store.DeliveryStore != null) {
                    PageSelectionStore.ShowDelivery = response.Store.DeliveryStore.ShowDeliveryForm;

                    DeliveryStore.ShowDeliveryForm = response.Store.DeliveryStore.ShowDeliveryForm;
                    DeliveryStore.DeliveryMethodField = response.Store.DeliveryStore.DeliveryMethodField;
                    DeliveryStore.CityField = response.Store.DeliveryStore.CityField;
                    DeliveryStore.StreetField = response.Store.DeliveryStore.StreetField;
                    DeliveryStore.BuildingNumberField = response.Store.DeliveryStore.BuildingNumberField;
                    DeliveryStore.ApartmentNumberField = response.Store.DeliveryStore.ApartmentNumberField;
                    DeliveryStore.FloorField = response.Store.DeliveryStore.FloorField;
                    DeliveryStore.EntranceField = response.Store.DeliveryStore.EntranceField;
                    DeliveryStore.DeliveryNotesField = response.Store.DeliveryStore.DeliveryNotesField;
                }

                // init DocumentStore
                const DocumentStore = useDocumentStore();
                if (response.Store.DocumentStore != undefined && response.Store.DocumentStore != null) {
                    PageSelectionStore.ShowDocument = response.Store.DocumentStore.ShowInvoiceForm;

                    DocumentStore.Comments = response.Store.DocumentStore.Comments;
                    DocumentStore.ShowInvoiceForm = response.Store.DocumentStore.ShowInvoiceForm;
                    DocumentStore.Title = response.Store.DocumentStore.Title;
                    DocumentStore.FormFields = response.Store.DocumentStore.FormFields;
                    DocumentStore.SendPreBuyDataToServer = response.Store.DocumentStore.SendPreBuyDataToServer;
                }

                // init DetailsStore
                const DetailsStore = useDetailsStore();
                if (response.Store.DetailsStore != undefined && response.Store.DetailsStore != null) {
                    PageSelectionStore.ShowDetails = response.Store.DetailsStore.ShowDetailsForm;

                    DetailsStore.ShowDetailsForm = response.Store.DetailsStore.ShowDetailsForm;
                    DetailsStore.Comments = response.Store.DetailsStore.Comments;
                    DetailsStore.FormFields = response.Store.DetailsStore.FormFields;
                }

                // init PaymentStore
                const PaymentStore = usePaymentStore();
                if (response.Store.PaymentStore != undefined && response.Store.PaymentStore != null) {
                    PageSelectionStore.ShowPayment = true;

                    PaymentStore.Comments = response.Store.PaymentStore.Comments;
                    PaymentStore.ButtonText = response.Store.PaymentStore.ButtonText;
                    PaymentStore.ButtonTextZeroSum = response.Store.PaymentStore.ButtonTextZeroSum;
                    PaymentStore.HideCreditCardFieldsOnZeroSum = response.Store.PaymentStore.HideCreditCardFieldsOnZeroSum;
                    PaymentStore.CardNumberField = response.Store.PaymentStore.CardNumberField;
                    PaymentStore.YearsField = response.Store.PaymentStore.YearsField;
                    PaymentStore.MonthsField = response.Store.PaymentStore.MonthsField;
                    PaymentStore.CvvField = response.Store.PaymentStore.CvvField;
                    PaymentStore.CardOwnerNameField = response.Store.PaymentStore.CardOwnerNameField;
                    PaymentStore.CardTZField = response.Store.PaymentStore.CardTZField;
                    PaymentStore.PaymentsField = response.Store.PaymentStore.PaymentsField;
                    PaymentStore.EmailField = response.Store.PaymentStore.EmailField;
                    PaymentStore.MobileField = response.Store.PaymentStore.MobileField;
                    PaymentStore.OpenSumField = response.Store.PaymentStore.OpenSumField;
                    PaymentStore.ApprovedTermsAndConditions = response.Store.PaymentStore.ApprovedTermsAndConditions;
                    PaymentStore.ShowTermsAndConditions = response.Store.PaymentStore.ShowTermsAndConditions;
                    PaymentStore.TermsAndConditionsUrl = response.Store.PaymentStore.TermsAndConditionsUrl;
                    PaymentStore.AllowMerchantMessages = response.Store.PaymentStore.AllowMerchantMessages;
                    PaymentStore.MustAllowMerchantMessages = response.Store.PaymentStore.MustAllowMerchantMessages;
                    PaymentStore.ShowMerchantMessages = response.Store.PaymentStore.ShowMerchantMessages;
                    PaymentStore.ShowPayPal = response.Store.PaymentStore.ShowPayPal;
                    PaymentStore.ShowUpayBitButton = response.Store.PaymentStore.ShowUpayBitButton;
                    PaymentStore.ShowPayMeBitButton = response.Store.PaymentStore.ShowPayMeBitButton;
                    PaymentStore.ShowCardcomBitButton = response.Store.PaymentStore.ShowCardcomBitButton;
                    PaymentStore.ShowApplePay = response.Store.PaymentStore.ShowApplePay;
                    PaymentStore.ShowGooglePay = response.Store.PaymentStore.ShowGooglePay;
                    PaymentStore.ShowRecaptcha = response.Store.PaymentStore.ShowRecaptcha;
                    PaymentStore.RecaptchaVersion = response.Store.PaymentStore.RecaptchaVersion;
                    PaymentStore.IsCredit = response.Store.PaymentStore.IsCredit;
                    PaymentStore.NumOfRetriesAllowed = response.Store.PaymentStore.NumOfRetriesAllowed;
                    PaymentStore.Is3DSecureEnabled = response.Store.PaymentStore.Is3DSecureEnabled;
                    PaymentStore.Was3DSActiveOnPageLoad = response.Store.PaymentStore.Was3DSActiveOnPageLoad;
                    PaymentStore.ShowSuccessPopup = response.Store.PaymentStore.ShowSuccessPopup;
                    PaymentStore.Commissions = response.Store.PaymentStore.Commissions;
                    PaymentStore.CartTitle = response.Store.PaymentStore.CartTitle;
                    PaymentStore.IsDirectDebit = response.Store.PaymentStore.IsDirectDebit;
                    PaymentStore.EnableHooxOffers = response.Store.PaymentStore.EnableHooxOffers;
                    PaymentStore.HooxID = response.Store.PaymentStore.HooxID;
                    PaymentStore.SupportedCardBrands = response.Store.PaymentStore.SupportedCardBrands;
                    PaymentStore.IsDirectDebitSplitPayments = response.Store.PaymentStore.IsDirectDebitSplitPayments;
                    PaymentStore.ApplePayApplicationData = response.Store.PaymentStore.ApplePayApplicationData;
                    PaymentStore.DonationType = response.Store.PaymentStore.DonationType;
                    PaymentStore.OrderSummeryTitle = response.Store.PaymentStore.OrderSummeryTitle;
                }

                // init CommonStore
                if (response.Store.CommonStore != undefined && response.Store.CommonStore != null) {
                    CommonStore.Uid = response.Store.CommonStore.Uid;
                    CommonStore.OldUid = response.Store.CommonStore.OldUid;
                    CommonStore.TerminalNumber = response.Store.CommonStore.TerminalNumber;
                    CommonStore.SuccessRedirectUrl = response.Store.CommonStore.SuccessRedirectUrl;
                    CommonStore.ErrorRedirectUrl = response.Store.CommonStore.ErrorRedirectUrl;
                    CommonStore.BackgroundImageUrl = response.Store.CommonStore.BackgroundImageUrl;
                    CommonStore.AdditionalDataJson = response.Store.CommonStore.AdditionalDataJson;
                    CommonStore.Language = language;

                    const openingText = response.Store.CommonStore.OpeningText;
                    if (openingText != undefined && openingText != null && openingText.trim() != '') {
                        CommonStore.OpeningText = openingText.trim();
                        PageSelectionStore.ShowIntro = true;
                    }

                    if (response.Store.CommonStore.IsSinglePage) {
                        CommonStore.IsSinglePage = true;

                        PageSelectionStore.ShowIntro = false;
                        PageSelectionStore.ShowIntro = false;
                        PageSelectionStore.ShowOrder = false;
                        PageSelectionStore.ShowDonation = false;
                        PageSelectionStore.ShowDelivery = false;
                        PageSelectionStore.ShowDocument = false;
                        PageSelectionStore.ShowDetails = false;
                        PageSelectionStore.ShowPayment = false;
                    }


                }

                PageSelectionStore.SetMenuItems();
                CommonStore.StoreInitialized = true;
            });
    },

    LoadDataLPC6(terminalNumber: string, lowProfileCode: string) {
        const { t, te } = useI18n();

        const apiUrl = 'api/LPC6/Load';

        const request = {
            TerminalNumber: terminalNumber,
            LowProfileCode: lowProfileCode,
            UserAgent: navigator.userAgent
        };
        const data = JSON.stringify(request);
        return fetch(`${process.env.VUE_APP_ROOT_API}/${apiUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        }).then(response => response.json()).
            then((response) => {

                const CommonStore = useCommonStore();

                if (response.IsRedirect && response.RedirectUrl != null && response.RedirectUrl != undefined && response.RedirectUrl.trim() != '') {
                    CommonStore.StoreInitialized = true;
                    CommonStore.IsError = response.IsError;
                    window.location.href = response.RedirectUrl;
                    return;
                }

                let language = response.Language.toLowerCase();

                if (language == 'gr')
                    language = 'de';

                document.querySelector('html')?.setAttribute("lang", language);

                i18nConfig.global.locale.value = language
                const direction = i18nConfig.global.t('css.htmlDir');
                const css = `/EA6/style${direction}.css?v=${process.env.VUE_APP_VER}`;
                document.querySelector('link[name="css-direction"]')?.setAttribute("href", css);

                if (response.IsError) {

                    CommonStore.IsError = true;

                    if (response.Message != null && response.Message != undefined) {
                        CommonStore.ErrorMessage = response.Message;

                        if (te(response.Message)) {
                            CommonStore.ErrorMessage = t(response.Message);
                        }
                    }

                    return;
                }

                if (response.Store == null || response.Store == undefined) {
                    CommonStore.IsError = true;

                    return;
                }

                // init CompanyInfoStore
                const CompanyInfoStore = useCompanyInfoStore();
                if (response.Store.CompanyInfoStore != undefined && response.Store.CompanyInfoStore != null) {
                    CompanyInfoStore.CompanyNumber = response.Store.CompanyInfoStore.CompanyNumber;
                    CompanyInfoStore.Name = response.Store.CompanyInfoStore.Name;
                    //document.title = CompanyInfoStore.Name; => Not relevant, will use Vue-Head plugin to set meta tags
                    CompanyInfoStore.Address1 = response.Store.CompanyInfoStore.Address1;
                    CompanyInfoStore.Address2 = response.Store.CompanyInfoStore.Address2;
                    CompanyInfoStore.Address3 = response.Store.CompanyInfoStore.Address3;
                    CompanyInfoStore.Tel = response.Store.CompanyInfoStore.Tel;
                    CompanyInfoStore.Email = response.Store.CompanyInfoStore.Email;
                    CompanyInfoStore.LogoURL = response.Store.CompanyInfoStore.LogoURL;
                    CompanyInfoStore.LogoAlt = response.Store.CompanyInfoStore.LogoAlt;
                    CompanyInfoStore.YoutubeLink = response.Store.CompanyInfoStore.YoutubeLink;
                    CompanyInfoStore.InstagramLink = response.Store.CompanyInfoStore.InstagramLink;
                    CompanyInfoStore.FacebookLink = response.Store.CompanyInfoStore.FacebookLink;
                    CompanyInfoStore.WhatsappLink = response.Store.CompanyInfoStore.WhatsappLink;
                    CompanyInfoStore.Comments = response.Store.CompanyInfoStore.Comments;
                    CompanyInfoStore.ShowGPayLogo = response.Store.CompanyInfoStore.ShowGPayLogo;
                    CompanyInfoStore.ShowMasterCardLogo = response.Store.CompanyInfoStore.ShowMasterCardLogo;
                    CompanyInfoStore.ShowVisaLogo = response.Store.CompanyInfoStore.ShowVisaLogo;
                    CompanyInfoStore.ShowAmexLogo = response.Store.CompanyInfoStore.ShowAmexLogo;
                    CompanyInfoStore.ShowDinersLogo = response.Store.CompanyInfoStore.ShowDinersLogo;
                    CompanyInfoStore.ShowIsracardLogo = response.Store.CompanyInfoStore.ShowIsracardLogo;
                    CompanyInfoStore.MetaTitle = response.Store.CompanyInfoStore.MetaTitle;
                    CompanyInfoStore.MetaDescription = response.Store.CompanyInfoStore.MetaDescription;
                    CompanyInfoStore.MetaImage = response.Store.CompanyInfoStore.MetaImage;
                    CompanyInfoStore.GoogleAnalyticsCodeOne = response.Store.CompanyInfoStore.GoogleAnalyticsCodeOne;
                }

                // init ProductsStore
                const ProductsStore = useProductsStore();
                if (response.Store.ProductsStore != undefined && response.Store.ProductsStore != null) {
                    ProductsStore.Title = response.Store.ProductsStore.Title;
                    ProductsStore.isDonation = response.Store.ProductsStore.IsDonation;
                    ProductsStore.showCurrencyConvert = response.Store.ProductsStore.ShowCurrencyConvert;
                    ProductsStore.currencies = response.Store.ProductsStore.Currencies;
                    ProductsStore.selectedCurrency = response.Store.ProductsStore.SelectedCurrency;
                    ProductsStore.couponCalculatedDiscount = response.Store.ProductsStore.CouponCalculatedDiscount;
                    ProductsStore.products = response.Store.ProductsStore.Products;
                    ProductsStore.deliveryMethods = response.Store.ProductsStore.DeliveryMethods;
                    ProductsStore.haveImages = response.Store.ProductsStore.HaveImages;
                    ProductsStore.isCouponActive = response.Store.ProductsStore.IsCouponActive;
                    ProductsStore.ShowProductPrice = response.Store.ProductsStore.ShowProductPrice;
                    ProductsStore.ShowTotalPrice = response.Store.ProductsStore.ShowTotalPrice;
                    ProductsStore.ItemsDisplayType = response.Store.ProductsStore.ItemsDisplayType;
                    ProductsStore.ManualSumField = response.Store.ProductsStore.ManualSumField;
                    ProductsStore.ShowVatInfo = response.Store.ProductsStore.ShowVatInfo;
                    ProductsStore.Vat = response.Store.ProductsStore.Vat;
                }

                // init DeliveryStore
                const DeliveryStore = useDeliveryStore();
                if (response.Store.DeliveryStore != undefined && response.Store.DeliveryStore != null) {
                    DeliveryStore.ShowDeliveryForm = response.Store.DeliveryStore.ShowDeliveryForm;
                    DeliveryStore.DeliveryMethodField = response.Store.DeliveryStore.DeliveryMethodField;
                    DeliveryStore.CityField = response.Store.DeliveryStore.CityField;
                    DeliveryStore.StreetField = response.Store.DeliveryStore.StreetField;
                    DeliveryStore.BuildingNumberField = response.Store.DeliveryStore.BuildingNumberField;
                    DeliveryStore.ApartmentNumberField = response.Store.DeliveryStore.ApartmentNumberField;
                    DeliveryStore.FloorField = response.Store.DeliveryStore.FloorField;
                    DeliveryStore.EntranceField = response.Store.DeliveryStore.EntranceField;
                    DeliveryStore.DeliveryNotesField = response.Store.DeliveryStore.DeliveryNotesField;
                }

                // init DocumentStore
                const DocumentStore = useDocumentStore();
                if (response.Store.DocumentStore != undefined && response.Store.DocumentStore != null) {
                    DocumentStore.Comments = response.Store.DocumentStore.Comments;
                    DocumentStore.ShowInvoiceForm = response.Store.DocumentStore.ShowInvoiceForm;
                    DocumentStore.Title = response.Store.DocumentStore.Title;
                    DocumentStore.FormFields = response.Store.DocumentStore.FormFields;
                }

                // init DetailsStore
                const DetailsStore = useDetailsStore();
                if (response.Store.DetailsStore != undefined && response.Store.DetailsStore != null) {
                    DetailsStore.ShowDetailsForm = response.Store.DetailsStore.ShowDetailsForm;
                    DetailsStore.Comments = response.Store.DetailsStore.Comments;
                    DetailsStore.FormFields = response.Store.DetailsStore.FormFields;
                }

                // init PaymentStore
                const PaymentStore = usePaymentStore();
                if (response.Store.PaymentStore != undefined && response.Store.PaymentStore != null) {
                    PaymentStore.Comments = response.Store.PaymentStore.Comments;
                    PaymentStore.ButtonText = response.Store.PaymentStore.ButtonText;
                    PaymentStore.HideCreditCardFieldsOnZeroSum = response.Store.PaymentStore.HideCreditCardFieldsOnZeroSum;
                    PaymentStore.CardNumberField = response.Store.PaymentStore.CardNumberField;
                    PaymentStore.YearsField = response.Store.PaymentStore.YearsField;
                    PaymentStore.MonthsField = response.Store.PaymentStore.MonthsField;
                    PaymentStore.CvvField = response.Store.PaymentStore.CvvField;
                    PaymentStore.CardOwnerNameField = response.Store.PaymentStore.CardOwnerNameField;
                    PaymentStore.CardTZField = response.Store.PaymentStore.CardTZField;
                    PaymentStore.PaymentsField = response.Store.PaymentStore.PaymentsField;
                    PaymentStore.EmailField = response.Store.PaymentStore.EmailField;
                    PaymentStore.MobileField = response.Store.PaymentStore.MobileField;
                    PaymentStore.OpenSumField = response.Store.PaymentStore.OpenSumField;
                    PaymentStore.CardOwnerCityField = response.Store.PaymentStore.CardOwnerCityField;
                    PaymentStore.CardOwnerAddressField = response.Store.PaymentStore.CardOwnerAddressField;
                    PaymentStore.CardOwnerZipcodeField = response.Store.PaymentStore.CardOwnerZipcodeField;
                    PaymentStore.ApprovedTermsAndConditions = response.Store.PaymentStore.ApprovedTermsAndConditions;
                    PaymentStore.ShowTermsAndConditions = response.Store.PaymentStore.ShowTermsAndConditions;
                    PaymentStore.TermsAndConditionsUrl = response.Store.PaymentStore.TermsAndConditionsUrl;
                    PaymentStore.AllowMerchantMessages = response.Store.PaymentStore.AllowMerchantMessages;
                    PaymentStore.MustAllowMerchantMessages = response.Store.PaymentStore.MustAllowMerchantMessages;
                    PaymentStore.ShowMerchantMessages = response.Store.PaymentStore.ShowMerchantMessages;
                    PaymentStore.ShowPayPal = response.Store.PaymentStore.ShowPayPal;
                    PaymentStore.ShowUpayBitButton = response.Store.PaymentStore.ShowUpayBitButton;
                    PaymentStore.ShowPayMeBitButton = response.Store.PaymentStore.ShowPayMeBitButton;
                    PaymentStore.ShowCardcomBitButton = response.Store.PaymentStore.ShowCardcomBitButton;
                    PaymentStore.ShowApplePay = response.Store.PaymentStore.ShowApplePay;
                    PaymentStore.ShowGooglePay = response.Store.PaymentStore.ShowGooglePay;
                    PaymentStore.ShowRecaptcha = response.Store.PaymentStore.ShowRecaptcha;
                    PaymentStore.RecaptchaVersion = response.Store.PaymentStore.RecaptchaVersion;
                    PaymentStore.IsCredit = response.Store.PaymentStore.IsCredit;
                    PaymentStore.NumOfRetriesAllowed = response.Store.PaymentStore.NumOfRetriesAllowed;
                    PaymentStore.Is3DSecureEnabled = response.Store.PaymentStore.Is3DSecureEnabled;
                    PaymentStore.Was3DSActiveOnPageLoad = response.Store.PaymentStore.Was3DSActiveOnPageLoad;
                    PaymentStore.ShowSuccessPopup = response.Store.PaymentStore.ShowSuccessPopup;
                    PaymentStore.ShowErrorPopup = response.Store.PaymentStore.ShowErrorPopup;
                    PaymentStore.Commissions = response.Store.PaymentStore.Commissions;
                    PaymentStore.CartTitle = response.Store.PaymentStore.CartTitle;
                    PaymentStore.IsDirectDebit = response.Store.PaymentStore.IsDirectDebit;
                    PaymentStore.SupportedCardBrands = response.Store.PaymentStore.SupportedCardBrands;
                    PaymentStore.ApplePayApplicationData = response.Store.PaymentStore.ApplePayApplicationData;
                    PaymentStore.DonationType = response.Store.PaymentStore.DonationType;
                }

                // init CommonStore
                if (response.Store.CommonStore != undefined && response.Store.CommonStore != null) {
                    CommonStore.Uid = response.Store.CommonStore.Uid;
                    CommonStore.OldUid = response.Store.CommonStore.OldUid;
                    CommonStore.TerminalNumber = response.Store.CommonStore.TerminalNumber;
                    CommonStore.SuccessRedirectUrl = response.Store.CommonStore.SuccessRedirectUrl;
                    CommonStore.ErrorRedirectUrl = response.Store.CommonStore.ErrorRedirectUrl;
                    CommonStore.BackgroundImageUrl = response.Store.CommonStore.BackgroundImageUrl;
                    CommonStore.AdditionalDataJson = response.Store.CommonStore.AdditionalDataJson;
                    CommonStore.Language = language;

                    const openingText = response.Store.CommonStore.OpeningText;
                    if (openingText != undefined && openingText != null && openingText.trim() != '') {
                        CommonStore.OpeningText = openingText.trim();
                    }
                }

                CommonStore.StoreInitialized = true;
            });
    },

    GetCities() {

        return fetch(`${process.env.VUE_APP_ROOT_API}/api/EA5/GetCities`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => response.json()).
            then((response) => { return response; });
    },

    GetStreets(cityCode: number) {

        return fetch(`${process.env.VUE_APP_ROOT_API}/api/EA5/GetStreets?cityCode=${cityCode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => response.json()).
            then((response) => {
                return response;
            });
    },

    GetCommissions(uid: string, sumToBill: number) {

        return fetch(`${process.env.VUE_APP_ROOT_API}/api/EA5/GetCommissions?uid=${uid}&sumToBill=${sumToBill}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => response.json()).
            then((response) => {
                return response;
            });
    }

}

const CouponService = {
    GetCoupon(uid: string, couponCode: string) {

        const ProductsStore = useProductsStore();
        const selectedProducts = ProductsStore.products.filter(p => p.SelectedBuyQuantity > 0);

        const request = {
            Store: {
                CommonStore: {
                    Uid: uid
                },
                ProductsStore:
                {
                    products: selectedProducts
                }
            },
            CouponCode: couponCode
        };
        const data = JSON.stringify(request)
        return fetch(`${process.env.VUE_APP_ROOT_API}/api/EA5/ValidateCoupon`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        }).then(response => response.json()).
            then((response) => {

                if (response.IsError) {
                    return { 'IsError': true, 'ErrorMessage': response.Message, 'Coupon': null };
                }

                return { 'IsError': false, 'ErrorMessage': '', 'Coupon': response.Store.ProductsStore.Coupon };
            });

    }
};

const ThreeDSecureService = {
    fingerPrintProcessed: false,
    DeviceFingerPrintTimeoutId: {} as NodeJS.Timeout | null,
    cardHolderChellengeIframe: {} as HTMLIFrameElement,
    fingerprintIframe: {} as HTMLIFrameElement,
    DeviceFingerPrintPromise: {} as Promise<Cardcom3DSecureResponse>,

    StartDeviceFingerPrint(response: Cardcom3DSecureResponse): Promise<Cardcom3DSecureResponse> {
        this.fingerPrintProcessed = false;
        this.fingerprintIframe = document.createElement('iframe') as HTMLIFrameElement;
        this.fingerprintIframe.hidden = true;
        window.document.body.appendChild(this.fingerprintIframe);

        const form = document.createElement('form') as HTMLFormElement;
        form.action = response._3ds_method;
        form.method = "post";
        form.id = "3dSecureFingerPrintForm";

        const input = document.createElement('input') as HTMLInputElement;
        input.value = response.threeDSMethodData;
        input.name = "threeDSMethodData";

        // Add to iframe all elements
        form.appendChild(input);
        this.fingerprintIframe.contentDocument?.body.appendChild(form);
        form.submit();
        // Set timeout to handle an error event

        this.DeviceFingerPrintPromise = new Promise<Cardcom3DSecureResponse>((resolve, reject) => {
            this.DeviceFingerPrintTimeoutId = setTimeout(() => {
                if (this.fingerPrintProcessed) {
                    reject();
                }
                if (this.DeviceFingerPrintTimeoutId) {
                    clearTimeout(this.DeviceFingerPrintTimeoutId);
                    this.DeviceFingerPrintTimeoutId = null;
                }
                this.FailDeviceFingerPrintAPI(response)?.then(response => response.json()).then((res) => {
                    if (this.fingerPrintProcessed) {
                        reject();
                    }
                    if (res.ResponseCode == 2) {
                        reject();
                    } else {
                        this.fingerPrintProcessed = true;
                        resolve(res);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    reject();
                });

            }, 20 * 1000)
        })
        return this.DeviceFingerPrintPromise;
    },

    async StartCardholderChallenge(response: Cardcom3DSecureResponse) {
        // ============== Redirect to page for further authentication, if version 1.X.. ============== //
        if (response._3ds_version.match("^1\\.")) {
            window.location.href = response._3ds_acsurl; // redirect
        }
        // ============== For other Version, which are 2.X... , open a dynamic iframe ============== //
        else {
            // Create Hidden iFrame
            this.cardHolderChellengeIframe = document.createElement('iframe') as HTMLIFrameElement;
            // Add Url
            this.cardHolderChellengeIframe.src = response._3ds_acsurl;
            this.cardHolderChellengeIframe.id = "3ds-cardholder-challenge";
            this.cardHolderChellengeIframe.style.zIndex = "999999"; // ensure it is always displayed
            this.cardHolderChellengeIframe.height = "95%";
            this.cardHolderChellengeIframe.width = "95%";
            this.cardHolderChellengeIframe.style.backgroundColor = "white";
            this.cardHolderChellengeIframe.style.position = "fixed";
            this.cardHolderChellengeIframe.style.left = "50%";
            this.cardHolderChellengeIframe.style.top = "50%";
            this.cardHolderChellengeIframe.style.transform = "translate(-50%, -50%)";

            //window.addEventListener("message", (event) => {
            //    if (event.data != undefined && event.data != null) {
            //        const data = event.data as String;
            //        if (data.indexOf('ThreeDSecureChallengeResult_') != -1) {
            //            const response = data.replace('ThreeDSecureChallengeResult_', '');
            //            const challengeResponse = JSON.parse(response) as Cardcom3DSecureResponse;
            //            console.log(challengeResponse);
            //            this.CloseCardHolderChellengeIframe();
            //        }
            //    }
            //}, false);

            // Inject into page
            window.document.body.appendChild(this.cardHolderChellengeIframe);

            //const commonStore = useCommonStore();

            //// if timeout - redirect to error page
            //setTimeout(() => {
            //    window.parent.location.href = commonStore.ErrorRedirectUrl;
            //    window.opener.location.href = commonStore.ErrorRedirectUrl;
            //}, 1000 * 10);
        }
    },

    FailDeviceFingerPrintAPI(response: Cardcom3DSecureResponse) {
        if (this.fingerPrintProcessed)
            return; // Don't FAIL if fingerprint was already processed

        const queryString = response._3ds_trxid;

        return fetch(`${process.env.VUE_APP_ROOT_API}/3DSecure/DeviceFingerPrintFailAterTimeOut?threeDSServerTransID=${queryString}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: '',
        });
    },

    AfterDeviceFingerPrint(responseData: string | Cardcom3DSecureResponse): Promise<Cardcom3DSecureResponse> {
        const resultPromise = new Promise<Cardcom3DSecureResponse>((resolve, reject) => {
            if (this.fingerPrintProcessed) {
                reject();
            }

            if (this.DeviceFingerPrintTimeoutId) {
                clearTimeout(this.DeviceFingerPrintTimeoutId);
                this.DeviceFingerPrintTimeoutId = null;
            }
            this.fingerPrintProcessed = true;
            const response = typeof (responseData) === "string" ? JSON.parse(responseData) : responseData;
            resolve(response);
        });
        return resultPromise;
    },

    CloseCardHolderChellengeIframe() {
        if (this.cardHolderChellengeIframe) {
            this.cardHolderChellengeIframe.style.zIndex = "-1";
            this.cardHolderChellengeIframe.remove();
        }
    }
};

export { StoreService, CouponService, ThreeDSecureService }
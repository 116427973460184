import { createI18n } from 'vue-i18n'
import en from './en'
import he from './he'
import de from './de'
import ar from './ar'
import fr from './fr'
import ru from './ru'
import es from './es'


// 1. Ready translated locale messages
// The structure of the locale message is the hierarchical object structure with each locale as the top property
const messages = {
    en,
    he,
    de,
    ar, 
    fr,
    ru,
    es
}


// 2. Create i18n instance with options
export const i18nConfig = createI18n({
    legacy: false,
    locale: 'he', // set locale
    fallbackLocale: 'he', // set fallback locale
    messages, // set locale messages
    // If you need to specify other options, you can set other options
    
})


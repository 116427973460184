import { defineStore } from 'pinia'
import { FormField } from '../components/input/FormField'
import { isNullOrWhitespace } from '../utils'

export class City {
    public Code = 0;
    public Name = "";
    public Streets = <Array<Street>>[];
}

export class Street {
    public Code = 0;
    public Name = "";
    public CityCode = 0;
}

export const useDeliveryStore = defineStore('DeliveryStore', {
    state: () => {
        return {
            ShowDeliveryForm: false,
            DeliveryMethodField: <FormField>{},
            CityField: <FormField>{},
            StreetField: <FormField>{},
            BuildingNumberField: <FormField>{},
            ApartmentNumberField: <FormField>{},
            FloorField: <FormField>{},
            EntranceField: <FormField>{},
            DeliveryNotesField: <FormField>{},

            Cities: <Array<City>>[],
            Streets: <Array<Street>>[]

        }
    },
    getters: {
    }
})



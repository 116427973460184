
const HooxService = {

    GetNewOffer(mobile: string, email: string, hooxId: string, totalAmount: number) {

        const request = {
            Mobile: mobile,
            Email: email,
            HooxId: hooxId,
            TotalAmount: totalAmount
        };
        const data = JSON.stringify(request)

        return fetch(`${process.env.VUE_APP_ROOT_API}/api/Hoox/GetNewOffer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        }).then(response => response.json()).
            then((response) => {
                return response;
            });
    }

}

export default HooxService;
import { useCommonStore } from "../../Store/CommonStore";

const CardcomBitService = {

    CreateNewBitSale(request: any) {
        const data = JSON.stringify(request)

        return fetch(`${process.env.VUE_APP_ROOT_API}/api/CardcomBit/CreateNewBitSale`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        }).then(response => response.json()).
            then((response) => {
                return response;
            });
    },

    IsInternalUrl(url: string) {
        if (url == undefined || url == null || url.trim() == '')
            return false;

        const skipRedirectAddresses = ['secure.cardcom.solutions', 'secure.cardcom.co.il'];
        const match = skipRedirectAddresses.find(a => url.trim().toLowerCase().indexOf(a) != -1);
        return match != undefined;
    },

    GetBitSaleDetails(paymentInitiationId: string) {

        const CommonStore = useCommonStore();

        const request = {
            TerminalNumber: CommonStore.TerminalNumber,
            PaymentInitiationId: paymentInitiationId
        };
        const data = JSON.stringify(request);

        return fetch(`${process.env.VUE_APP_ROOT_API}/api/CardcomBit/GetBitSaleDetails`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        }).then(response => response.json()).
            then((response) => {
                return response;
            });
    },

    GetQrBarcodeUrl(qrBarcodeBytes: any) {
        if (qrBarcodeBytes) {
            const binaryString = atob(qrBarcodeBytes);
            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            const blob = new Blob([bytes.buffer], { type: "image/jpeg" });
            const imageUrl = URL.createObjectURL(blob);
            return imageUrl;
        }
        else {
            return '';
        }
    }

}

export default CardcomBitService;
import { defineStore } from 'pinia'
import { isNullOrWhitespace } from '../utils'

export class ErrorInforamtion { // move to pini store name init
    validationId: Number = -1
    massage: string = ""
    groupId: Number = 0
}

export const useErrorsStore = defineStore('ErrorsStore', {
    state: () => {
        return {
            //                Group ,       Id ,    Massage
            ErrorList: new Map<Number, Map<Number, ErrorInforamtion>>(),
            ErrorsCount: Number(0)
        }
    },
    actions: {
        AddError(error: ErrorInforamtion) {
            if (this.ErrorList.has(error.groupId) == false) {
                this.ErrorList.set(error.groupId, new Map<Number, ErrorInforamtion>())
            }
            const group = <Map<Number, ErrorInforamtion>>this.ErrorList.get(error.groupId);

            // Add if not there
            if (group.has(error.validationId) == false) {
                group.set(error.validationId, error);
                this.ErrorsCount += 1;
            }
        },

        RemoveError(groupId: number, validationId: Number) {
            if (this.ErrorList.has(groupId) == false) {
                return;
            }
            const group = <Map<Number, ErrorInforamtion>>this.ErrorList.get(groupId);

            if (group.has(validationId)) {
                group.delete(validationId);
                this.ErrorsCount -= 1;
            }
        }
    }
})




const ValidationMethods = {
    ValidateIdNumber(idNumber: string) {
        if (idNumber.length > 9 || idNumber.length < 5) {
            return false;
        }
        if (isNaN(Number(idNumber))) {
            return false;
        }
        while (idNumber.length < 9) {
            idNumber = "0" + idNumber;
        }
        let mone = 0, incNum;
        for (let i = 0; i < 9; i++) {
            incNum = Number(idNumber.charAt(i));
            incNum *= (i % 2) + 1;
            if (incNum > 9) incNum -= 9;
            mone += incNum;
        }
        if (mone % 10 == 0)
            return true;
        else
            return false;
    },

}

export { ValidationMethods }

export default
    {
        IsValidCurrentForm: function (): boolean { return true; }
    }

import { defineStore } from 'pinia'
import { isNullOrWhitespace } from '../utils'

export enum DonationType {
    SingleTime = 'SINGLETIME',
    Monthly = 'MONTHLY'
}

export enum RecurringDonationPeriodType {
    OneYear = 'ONEYEAR',
    Unlimited = 'UNLIMITED'
}

export const useDonationStore = defineStore('DonationStore', {
    state: () => {
        return {
            Val: {
                DonationType: DonationType.SingleTime,
                RecurringDonationPeriodType: RecurringDonationPeriodType.OneYear,
                Donation: ''
            }
        }
    },
    getters: {
    }
})
import { defineStore } from 'pinia'
import { isNullOrWhitespace } from '../utils'


export const useCompanyInfoStore = defineStore('CompanyInfoStore', {
    state: () => {
        return {
            CompanyNumber: 0,
            Name: '',
            Address1: '',
            Address2: '',
            Address3: '',
            Tel: '',
            Email: '',
            LogoURL: '',
            LogoAlt: '',
            YoutubeLink: '',
            InstagramLink: '',
            FacebookLink: '',
            WhatsappLink: '',
            Comments: '',
            ShowGPayLogo: false,
            ShowMasterCardLogo: false,
            ShowVisaLogo: false,
            ShowAmexLogo: false,
            ShowDinersLogo: false,
            ShowIsracardLogo: false,
            MetaTitle: '',
            MetaDescription: '',
            MetaImage: '',
            GoogleAnalyticsCodeOne: ''
        }
    },
    getters: {
        isShowLinks() {
            if (isNullOrWhitespace(this.YoutubeLink) && isNullOrWhitespace(this.InstagramLink) && isNullOrWhitespace(this.FacebookLink) && isNullOrWhitespace(this.WhatsappLink))
                return false;

            return true;
        },
        isShowBizInfo() {
            if (isNullOrWhitespace(this.Name))
                return false;

            return true;
        },


    }
})


